/*-----------------------------------------------
|   mds buttons
-----------------------------------------------*/
// $theme-mds-btn-colors: (
//   'primary': $primary,
//   'success': $success,
//   'info': $info,
//   'warning': $warning,
//   'danger': $danger,
//   'default': $dark
// );

// @each $color, $value in $theme-mds-btn-colors {
//   .btn-#{$color} {
//     @include mds-button-variant($color);
//   }
// }

/* -------------------------------------------------------------------------- */
/*                                mds button                              */
/* -------------------------------------------------------------------------- */
@mixin mds-button-variant($color) {
  --#{$prefix}btn-active-bg: $gray-200;
  --#{$prefix}btn-active-color: $color;
  color: $color;
  background-color: $gray-soft;
  border-color: $gray-200;
  box-shadow: none;

  @include hover-focus {
    color: $color;
    background-color: $gray-100;
    border-color: $gray-100;
    &:not(.disabled):not(:disabled) {
      color: $color;
      box-shadow: none;
    }
  }
}

@mixin button-outline-mds-variant(
  $color,
  $border-color,
  $color-hover: color-contrast($color),
  $active-background: $color,
  $active-border: $gray-200,
  $active-color: color-contrast($active-background)
) {
  --#{$prefix}btn-color: #{$color};
  --#{$prefix}btn-border-color: #{$border-color};
  --#{$prefix}btn-hover-color: #{$color-hover};
  --#{$prefix}btn-hover-bg: #{$active-background};
  --#{$prefix}btn-hover-border-color: #{$active-border};
  --#{$prefix}btn-focus-shadow-rgb: #{to-rgb($color)};
  --#{$prefix}btn-active-color: #{$active-color};
  --#{$prefix}btn-active-bg: #{$active-background};
  --#{$prefix}btn-active-border-color: #{$active-border};
  --#{$prefix}btn-active-shadow: #{$btn-active-box-shadow};
  --#{$prefix}btn-disabled-color: #{$color};
  --#{$prefix}btn-disabled-bg: transparent;
  --#{$prefix}gradient: none;
}
