.img-object-fit-cover {
  object-fit: cover;
}

.mds-table {
  table {
    border-collapse: separate;
    border-spacing: 0 0.5em;

    thead {
      tr {
        border-color: #CBD0DD;
        th {
          font-size: 13px !important;
        }
      }
    }

    tbody tr {
      background: #ffffff;
      -webkit-box-shadow: 2px 10px 8px -13px rgba(34, 60, 80, 0.2);
      -moz-box-shadow: 2px 10px 8px -13px rgba(34, 60, 80, 0.2);
      box-shadow: 2px 10px 8px -13px rgba(34, 60, 80, 0.2);

      td {
        &:first-child {
          border-top-left-radius: 5px !important;
          border-bottom-left-radius: 5px !important;
        }

        &:last-child {
          border-top-right-radius: 5px !important;
          border-bottom-right-radius: 5px !important;
        }
      }
    }
  }
}

.text-right {
  text-align: right;
}

.custom-drop {
  .drop-icon {
    font-size: 17px;
  }

  .is-invalid {
    border-color: #ed2000 !important;
  }
}

.text-cutting {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

// TODO: All buttons default ?
.btn-md {
  --mds-btn-padding-x: 1.5rem;
  --mds-btn-padding-y: 0.630rem;
}

.btn-light-success {
  color: #1C6C09; // #25b003
  background-color: #d9fbd0;

  &:hover, &:active, &:focus {
    color: #1C6C09; // #25b003
    background-color: #d1ffc4;
  }
}

.documents-tab {
  .contract-card {
    margin-top: 21px;

    .card {
      min-height: 161px;
    }
  }

  .add-document {
    margin-top: 5px;
  }
}

.mds-tabs {
  ul {
    li {
      &:first-child {
        a {
          margin-left: 0;
        }
      }

      a {
        font-size: 15px;
        font-weight: 700;

        &.active, &:hover {
          color: var(--mds-link-color) !important;

          &::after {
            width: 100%;
          }
        }

        &::after {
          content: '';
          display: block;
          height: 1px;
          position: absolute;
          width: 0;
          bottom: -1px;
          transition: width .3s;
          background: var(--mds-link-color);
        }
      }
    }
  }
}

.mds-badge {
  .badge-label {
    line-height: normal;
  }
}

.border-transparent {
  border-color: transparent !important;
}

/* -------------------------------------------------------------------------- */
/*                btn-{prefix}-md-{prefix} - our buttons styles               */
/* -------------------------------------------------------------------------- */

.btn-outline-md-primary {
  --mds-btn-color: #3874ff;
  --mds-btn-hover-color: #004dff;
  --mds-btn-border-color: #85a9ff;
  --mds-btn-active-color: #004dff;
  --mds-btn-hover-border-color: transparent;
  --mds-btn-active-border-color: transparent;
  --mds-btn-hover-bg: rgba(var(--mds-primary-100-rgb), 1);
  --mds-btn-active-bg: rgba(var(--mds-primary-100-rgb), 1);
}

.border-static-outline-primary {
  --mds-btn-border-color: #85a9ff;
  --mds-btn-hover-border-color: #85a9ff;
  --mds-btn-active-border-color: #85a9ff;
}

.dark {
  .btn-outline-md-primary {
    --mds-btn-bg: #0f111a;
    --mds-btn-color: #85a9ff;
    --mds-btn-hover-color: #85a9ff;
    --mds-btn-border-color: #31374a;
  }

  .border-static-outline-primary {
    --mds-btn-border-color: #31374a;
    --mds-btn-active-border-color: #31374a;
    --mds-btn-hover-border-color: #31374a;
  }

  .mds-table {
    table {
      tbody {
        tr {
          background: #141824;
        }
      }
    }
  }
}

.mds-pagination {
  li {
    button {
      font-size: .64rem;
      padding: 0.5rem 0.75rem;
    }
  }
}

.auth-wave {
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: calc(100% - 45vw);
}

@media screen and (min-width: 576px) {
  .auth-wave {
    background-image: url(/assets/images/auth/bg.svg);
  }
}

@media screen and (min-width: 576px) {
  .text-md-right {
    text-align: right;
  }
  .content-max-width {
    max-width: max-content;
  }
}

.btn-md-spinner {
  height: 39.44px !important;
}