/* -------------------------------------------------------------------------- */
/*                                  Override                                  */
/* -------------------------------------------------------------------------- */
.dark {
  @each $color, $value in $theme-all-colors {
    .dark__bg-#{$color} {
      background-color: $value !important;
    }
    .dark__text-#{$color} {
      color: $value !important;
    }
  }
  .dark__bg-1200 {
    background-color: $dark-gray-soft !important;
  }
  .form-select, .form-control {
    &:disabled {
      background-color: rgba(var(--mds-gray-100-rgb), 0.7);
    }
  }
  .flatpickr-calendar {
    background: #141824;

    .flatpickr-months {
      .flatpickr-month {
        .flatpickr-current-month {
          background: #141824 !important;
        }
      }
    }
  }
}

label {
  font-size: 12px;
}

.modal {
  .modal-title {
    font-size: 20px;
  }
}

.form-control {
  &:focus {
    border-color: #3874ff;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0,0,0,0), 0 0 0 0.25rem rgba(56,116,255,.25);
  }
}

.bg-primary-100, .btn-mds-primary, .btn-outline-md-primary {
  &:disabled {
    color: var(--mds-primary);
  }
}

.form-select, .form-control {
  &:disabled {
    color: rgba(var(--mds-600-rgb), 1) !important;
    background-color: #f5f7fa;
    opacity: 1;
  }
}

.navbar-toggle-icon {
  .toggle-line, &::before, &::after {
    background-color: var(--mds-primary-100);
  }
}

.theme-control-toggle {
  .theme-control-toggle-label {
    &:hover {
      color: var(--mds-theme-control-toggle-color);
    }
  }
}

@media screen and (min-width: 992px) {
  .navbar-vertical {
    &.navbar-expand-lg {
      width: 12.875rem;

      &~.navbar-top~.content {
        margin-left: 12.875rem;
      }
    }
  }
}